.header-complete {
  color: #37aefe !important;
}

.wrapper-folio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool {
  color: #ff6c00 !important;
  background-color: #7e7e7e !important;
  max-width: 300px;
  min-width: 200px;
  border-radius: 15px !important;
  padding: 5px !important;
  opacity: 1 !important;
}
.question {
  margin-top: 0;
  margin-left: 10px;
  align-self: center;
}
.submit{
  align-self: flex-start;
  
}
@media (max-width: 767px) {
  .question {
    margin-top: 10px;
    margin-left: 0px;
    align-self: center;
  }

  .submit{
    align-self: center;
  }
}

#videoDemo {
  width: 100%;
  height: 80vh;
}

@media (max-width: 767px) {
  #videoDemo {
    height: 100%;
  }
}
